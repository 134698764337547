import { EmployerHeader, type ActiveSubTabId } from '@seek/adv-header-footer';
import { useMemo } from 'react';
import { hotjar } from 'react-hotjar';

import { isStaging, isProduction, getLocale, getSite } from '../../config';
import type { HeaderFooterAccountInfo } from '../../types/HeaderFooterAccount';

export interface AdCentreHeaderProps {
  adCentreAccountInfo: HeaderFooterAccountInfo | null;
  isSeekStaff: boolean;
  accountNumber: string;
  advertiserId: string;
  advertiserName: string;
  activeTabId: 'analytics';
  activeSubTabId: ActiveSubTabId;
}

export const AdCentreHeader = ({
  adCentreAccountInfo,
  isSeekStaff,
  accountNumber,
  advertiserId,
  advertiserName,
  activeTabId,
  activeSubTabId,
}: AdCentreHeaderProps) => {
  // mock seek user header(similar to seekStaffHeader)
  // TODO: remove after permission control added and act returned

  const seekAvertiserText =
    accountNumber.length > 0
      ? `${advertiserName} (Account Number: ${accountNumber} | SEEK ID: ${advertiserId})`
      : advertiserName;

  let { headerFooterContext } = adCentreAccountInfo || {};

  if (
    headerFooterContext &&
    isSeekStaff &&
    !headerFooterContext?.identityContext?.act &&
    advertiserId
  ) {
    // mock seeker user header(similar to seekStaffHeader)
    // TODO: remove after permission control added and act returned
    headerFooterContext = {
      ...(headerFooterContext || {}),
      identityContext: {
        email: seekAvertiserText,
        // mockact
        act: {
          operatorEmail: headerFooterContext?.identityContext?.email || '',
          isCsView: false,
          csToggleHref: '',
        },
      },
    };
  }

  const headerProps = {
    ...headerFooterContext,
    activeTabId,
    activeSubTabId,
    id: 'basic-8',
    locale: getLocale(),
    site: getSite(),
    isStaging: isStaging(),
  };

  useMemo(() => {
    if (isProduction() && headerFooterContext?.user?.id) {
      const { id } = headerFooterContext.user;
      hotjar.identify(id, {
        accountNumber,
        experience: 'new',
      });
    }
  }, [headerFooterContext, accountNumber]);

  return <EmployerHeader {...headerProps} />;
};
