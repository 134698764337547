import { useTranslations } from '@vocab/react';
import { Text } from 'braid-design-system';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { AdStatCard } from '../../components/AdStatCard/AdStatCard';
import { AdStatErrorOrLoading } from '../../components/AdStatErrorOrLoading/AdStatErrorOrLoading';
import { useFeatureToggleData, FEATURE_BENCHMARKS } from '../../featureToggle';
import { useApplicationPerformance } from '../../hooks/useApplicationPerformance';

import translations from './.vocab';

export interface ApplicationPerformanceProps {
  hasBorder?: boolean;
  isStandalone?: boolean;
  updateHasAdRating?: (hasAdRating: boolean) => void;
  onQueryError?: () => void;
}

export const ApplicationPerformance = ({
  hasBorder = false,
  isStandalone,
  updateHasAdRating,
  onQueryError,
}: ApplicationPerformanceProps) => {
  const { t } = useTranslations(translations);
  const { jobId: jobIdParam } = useParams<{
    jobId: string;
  }>();

  const { isLoading, data, error } = useApplicationPerformance({
    jobId: jobIdParam!,
  });

  const isBenchmarksFeatureEnabled = useFeatureToggleData(
    FEATURE_BENCHMARKS,
    false,
  );

  useEffect(() => {
    if (data?.hasAdRating) {
      updateHasAdRating?.(true);
    }
  }, [updateHasAdRating, data]);

  const hasQueryError = error || !data;

  useEffect(() => {
    if (!isLoading && hasQueryError && onQueryError) {
      onQueryError();
    }
  }, [isLoading, hasQueryError, onQueryError]);

  if ((isLoading && !data) || hasQueryError) {
    return (
      <AdStatErrorOrLoading
        headingLabel={t('Candidates')}
        headingTooltip={t(
          'A candidate completed their application for your job ad on SEEK',
        )}
        hasBorder={hasBorder}
        isLoading={isLoading}
      />
    );
  }

  const {
    adRatingConfidence,
    applications,
    mobileViewsInPercentage,
    similarAdsAverage,
    hasAdRating,
    isLinkOutAd,
  } = data;

  return (
    <AdStatCard
      adRatingConfidence={adRatingConfidence}
      headingLabel={isLinkOutAd ? t('Application starts') : t('Candidates')}
      headingTooltip={
        <Text size="small">
          {isLinkOutAd
            ? t('A candidate clicked on apply for your job ad on SEEK')
            : t(
                'A candidate completed their application for your job ad on SEEK',
              )}
        </Text>
      }
      statCount={applications}
      mobileInPercentage={mobileViewsInPercentage}
      comparisonToSimilarAds={
        similarAdsAverage !== null && similarAdsAverage > 0
          ? (applications - similarAdsAverage) / similarAdsAverage
          : null
      }
      similarAdsAverage={similarAdsAverage}
      displayMarketPerformance={hasAdRating && isBenchmarksFeatureEnabled}
      hasBorder={hasBorder}
      isStandalone={isStandalone}
    />
  );
};
